import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment/src/moment';

export default function useSubscribersList() {

    // Use toast
    const toast = useToast();

    const refSubscriberListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'email', sortable: true, label: 'Correo' },
        { key: 'state', sortable: true, label: 'MailChimp' },
        { key: 'createdAt', sortable: true, label: 'Fecha de Suscripción' },
        // { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalSubscribers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('createdAt');
    const isSortDirDesc = ref(true);

    const dataMeta = computed(() => {
        const localItemsCount = refSubscriberListTable.value ? refSubscriberListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalSubscribers.value,
        }
    });

    const refetchData = () => {
        refSubscriberListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });

    const fetchSubscribers = (ctx, callback) => {

        store
            .dispatch('app-subscriber/fetchSubscribers', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
            })
            .then(response => {
                const { subscribers, total } = response.data;
                callback(subscribers);
                totalSubscribers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de suscritos',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchSubscribers,
        tableColumns,
        perPage,
        currentPage,
        totalSubscribers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refSubscriberListTable,

        refetchData,
    }
}