import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSubscribers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/subscribers`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}