<template>

	<div>	
		
		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
													   
					<!-- Search -->
					<b-col
						cols="12"
						md="4"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar suscritor ..."
								trim
							/>							
						</div>
					</b-col>
				</b-row>

			</div>

			<b-table
				ref="refSubscriberListTable"
				class="position-relative"
				:style="totalSubscribers < 3 && totalSubscribers > 0 ? 'height: 250px' : 'height: inherit'"
				:items="fetchSubscribers"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc">
				
                <template #cell(state)="data">
                    <b-badge
                        pill
                        :variant="`light-${data.item.state ? 'success' : 'danger'}`"
                    >
                        {{ data.item.state ? 'SUSCRITO' : 'NO SUSCRITO' }}
                    </b-badge>
                </template>
								
				<template #cell(createdAt)="data">
					{{ formatDate(data.item.createdAt) }}
				</template>
				
			</b-table>
			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalSubscribers"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>        
	</div>
</template>

<script>

	import {BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormDatepicker} from 'bootstrap-vue';
	import { useToast } from 'vue-toastification/composition';
	import store from '@/store';
	import VueSweetalert2 from 'vue-sweetalert2';    
	import { onUnmounted, ref, reactive } from '@vue/composition-api';
	import useSubscribersList from './useSubscribersList';
	import subscriberStoreModule from '../subscriberStoreModule';
	import Vue from 'vue';        
    import moment from 'moment';
	import 'animate.css';
    import vSelect from 'vue-select';
	
	Vue.use(VueSweetalert2);

	export default {
		components: {
			BCard,
			BRow,
			BCol,
			BFormInput,
			BButton,
			BTable,
			BMedia,
			BAvatar,
			BLink,
			BBadge,
			BDropdown,
			BDropdownItem,
			BPagination,
			BFormDatepicker,	
            
            // 3rd party
            vSelect,
            moment
		},
		data() {                                   
			return {
				PublicUrl: process.env.VUE_APP_HOST,                
			}
		}, 
		setup() {

			// Use toast
			const toast = useToast();

			const USER_APP_STORE_MODULE_NAME = 'app-subscriber';

			// Register module
			if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, subscriberStoreModule);

			// UnRegister on leave
			onUnmounted(() => {
				if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
			});
            
            const formatDate = (date) => {
				return moment(date).format('DD/MM/YYYY HH:mm:ss');
			}
													
			const {
				fetchSubscribers,
				tableColumns,
				perPage,
				currentPage,
				totalSubscribers,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refSubscriberListTable,
				refetchData,
								
			} = useSubscribersList();
			
			return {
				fetchSubscribers,
				tableColumns,
				perPage,
				currentPage,
				totalSubscribers,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refSubscriberListTable,
				refetchData,
                formatDate
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
		align-items: center;
	}

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>